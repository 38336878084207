import * as React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import "../category-page.css";
import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import _ from "lodash";
import image404 from "../images/404_undraw.png";
import { useMediaQuery } from "react-responsive";

const Page404 = styled.div`
  margin: 0 auto;
  max-width: 835px;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 100px;
`;

const Image404 = styled.img`
  height: 50%;
  width: 70%;
`;

export const PageTemplate = (props) => {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1400 });
    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1400 });
    return isMobile ? children : null;
  };

  return (
    <div className="categoy-page">
      <Layout location={props.location} title={props.title}>
        <SEO title="All posts" />
        <Desktop>
          <div className="content-area">
            <div className="title-category">
              <h3>
                <span>{_.startCase(props.page_title)}</span>
              </h3>
            </div>
            <ol className="post-ordered-list" style={{ listStyle: `none` }}>
              {props.posts.map((post, i) => {
                const image = getImage(post.frontmatter.thumbnail);
                const title = post.frontmatter.title || post.fields.slug;

                return (
                  <li className="post-flex-list" key={post.fields.slug}>
                    <div className="post-thumbnail">
                      <a
                        className={`category-badge ${post.frontmatter.categories}`}>
                        <span>{_.upperCase(post.frontmatter.categories)}</span>
                      </a>
                      <GatsbyImage
                        imgStyle={{
                          "object-fit": "contain",
                          opacity: "1",
                        }}
                        // className="index-thumbnail"
                        image={image}
                        alt={post.frontmatter.description}
                      />
                    </div>
                    <div className="post-content">
                      <article
                        className="post-list-item"
                        itemScope
                        itemType="http://schema.org/Article">
                        <header>
                          <h2>
                            <Link to={post.fields.slug} itemProp="url">
                              <span
                                className="post-heading"
                                itemProp="headline">
                                {title}
                              </span>
                            </Link>
                          </h2>
                          <small>{post.frontmatter.date}</small>
                        </header>
                        <section>
                          <p
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html:
                                post.frontmatter.description || post.excerpt,
                            }}
                            itemProp="description"
                          />
                        </section>
                      </article>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
        </Desktop>
        <Mobile>
          <div className="mobile-content-area">
            <div className="title-category">
              <h3>
                <span>{_.startCase(props.page_title)}</span>
              </h3>
            </div>
            <ol className="post-ordered-list" style={{ listStyle: `none` }}>
              {props.posts.map((post, i) => {
                const image = getImage(post.frontmatter.thumbnail);
                const title = post.frontmatter.title || post.fields.slug;

                return (
                  <li
                    className="mobile-post-flex-list-index"
                    key={post.fields.slug}>
                    <div className="post-thumbnail">
                      <a
                        className={`category-badge ${post.frontmatter.categories}`}>
                        <span>{_.upperCase(post.frontmatter.categories)}</span>
                      </a>
                      <GatsbyImage
                        imgStyle={{
                          "object-fit": "contain",
                          opacity: "1",
                        }}
                        // className="index-thumbnail"
                        image={image}
                        alt={post.frontmatter.description}
                      />
                    </div>
                    <div className="mobile-post-content">
                      <article
                        className="post-list-item"
                        itemScope
                        itemType="http://schema.org/Article">
                        <header>
                          <h2>
                            <Link to={post.fields.slug} itemProp="url">
                              <span
                                className="post-heading"
                                itemProp="headline">
                                {title}
                              </span>
                            </Link>
                          </h2>
                          <small>{post.frontmatter.date}</small>
                        </header>
                        <section>
                          <p
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html:
                                post.frontmatter.description || post.excerpt,
                            }}
                            itemProp="description"
                          />
                        </section>
                      </article>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
        </Mobile>
      </Layout>
    </div>
  );
};

export class PageNotFound extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={this.props.title}>
        <SEO title="404: Not Found" />
        <Page404>
          <div className="image-404">
            <Image404 src={image404} />
          </div>
          <div className="text-404">
            <h1>Page not Found.</h1>
            <p>Sorry, but the page you were looking for could not be found.</p>
          </div>
        </Page404>
      </Layout>
    );
  }
}

export default PageTemplate;
